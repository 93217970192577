<template>
  <div class="register">
    <Header />
    <div class="register-header">
      <b-container class="register-header-container">
        <b-row class="register-header-row">
          <div class="col-lg-12 col-md-6">
            <div class="register-header">
              <h1>Ubah Nomor Rekening</h1>
              <!-- <p>Mohon isi data berikut agar Anda bisa memulai investasi.</p> -->
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
    <div class="register-form">
      <b-container class="register-form-container">
        <b-form @submit.prevent="handleSubmit">
          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6">
              <div class="register-header text-left">
                <h1>Data Rekening</h1>
                <p>Bank Account</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="ekyc-form-input">
                <h3>Nama Bank</h3>
                <p>Bank Name</p>
                <vs-select
                  autocomplete
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="id_bank_1"
                  name="id_bank_1"
                  id="id_bank_1"
                  style="width: 100%"
                >
                  <vs-select-item
                    :key="index"
                    :value="bank.id"
                    :text="bank.bank_name"
                    v-for="(bank, index) in banks"
                  />
                </vs-select>
                <div class="error mt-3" v-if="!$v.id_bank_1.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="ekyc-form-input">
                <h3>Nomor Rekening</h3>
                <p>Account Number</p>
                <vs-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="number"
                  v-model="payment_acc_number_1"
                  required
                />
                <div
                  class="error mt-3"
                  v-if="!$v.payment_acc_number_1.required"
                >
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>
          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6">
              <div class="register-header text-left"></div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="ekyc-form-input">
                <h3>Nama Sesuai KTP</h3>
                <p>Full Name</p>
                <vs-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="payment_acc_name_1"
                />
                <div class="error mt-3" v-if="!$v.payment_acc_name_1.required">
                  <p>Wajib diisi</p>
                </div>
              </div>
            </div>
          </b-row>
          <b-row class="justify-content-md-start mb-4">
            <div class="col-lg-3 col-md-6">
              <div class="register-header text-left"></div>
            </div>
            <div class="col-lg-9 col-md-6">
              <div class="ekyc-form-radio">
                <h3>Mata Uang</h3>
                <p>Currency</p>
                <b-row>
                  <div class="col-lg-4 col-md-6">
                    <vs-radio
                      v-model="id_currency_1"
                      vs-name="id_currency_1"
                      vs-value="1"
                      >IDR: Indonesia Rupiah</vs-radio
                    >
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <vs-radio
                      v-model="id_currency_1"
                      vs-name="id_currency_1"
                      vs-value="2"
                      >USD: US Dollar</vs-radio
                    >
                  </div>
                </b-row>
                <div class="error mt-3" v-if="!$v.id_currency_1.required">
                  <p>Wajib diisi</p>
                </div>

                <div></div>
              </div>
            </div>
          </b-row>
          <b-row class="justify-content-end btn-submit-row mb-4">
            <div class="col-lg-2 col-md-6 text-right">
              <b-button
                block
                type="submit"
                :disabled="loading"
                @click.once="handleSubmit()"
                class="btn-tertiary py-2 px-4"
              >
                <div v-if="loading">
                  <div class="spinner-border spinner-border-sm"></div>
                  Loading
                </div>
                <div v-else>Submit</div>
              </b-button>
            </div>
          </b-row>
        </b-form>

        <div class="modal-register">
          <b-modal
            id="modal-failed-register"
            v-model="failedShow"
            size="md"
            class="modal-failed-register"
            centered
            hide-footer
          >
            <div class="d-block text-center">
              <img
                src="@/assets/img/ekyc/data-not-correct.svg"
                alt="data-not-correct"
              />
              <h1 class="header-modal p-4 my-2 text-dark">
                Isian belum sesuai
              </h1>
              <p>Cek kembali form yang anda isi</p>
              <!-- <p>{{errorMessage}}</p> -->
            </div>
          </b-modal>
        </div>
      </b-container>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import axios from "axios";
import { mapGetters } from "vuex";

import { required } from "vuelidate/lib/validators";

import instance from "@/utils/api";

export default {
  name: "ChangeAccount",
  metaInfo: {
    title: "Ganti Nomor Rekening | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
  },
  validations: {
    id_bank_1: {
      required,
    },
    payment_acc_number_1: {
      required,
    },
    payment_acc_name_1: {
      required,
    },
    id_currency_1: {
      required,
    },
  },
  data() {
    return {
      banks: [],
      bank: [],
      id_bank_1: null,
      payment_acc_number_1: "",
      payment_acc_name_1: "",
      id_currency_1: "",
      id_bank_2: "",
      payment_acc_number_2: "",
      payment_acc_name_2: "",
      id_currency_2: "",
      id_bank_3: "",
      payment_acc_number_3: "",
      payment_acc_name_3: "",
      id_currency_3: "",
      expiration_date_id: "",
      bank_branch_1: "",

      failedShow: false,
      loading: false,
      disabled: false,
    };
  },
  mounted() {
    this.fetchBanks();
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      check: "auth/check",
      user: "auth/user",
      access_token: "auth/access_token",
    }),
  },
  methods: {
    async fetchBanks() {
      let response = await axios({
        method: "GET",
        url: "banks",
        headers: {},
      });
      this.banks = response.data.data;
    },
    handleSubmit() {
      let formData = new FormData();
      formData.append("id_bank_1", this.id_bank_1);
      formData.append("bank_branch_1", this.bank_branch_1);
      formData.append("id_currency_1", this.id_currency_1);
      formData.append("payment_acc_number_1", this.payment_acc_number_1);
      formData.append("payment_acc_name_1", this.payment_acc_name_1);
      // formData.append("id_bank_2", this.id_bank_2);
      // formData.append("bank_branch_2", this.bank_branch_1);
      // formData.append("id_currency_2", this.id_currency_2);
      // formData.append("payment_acc_number_2", this.payment_acc_number_2);
      // formData.append("payment_acc_name_2", this.payment_acc_name_2);
      // formData.append("id_bank_3", this.id_bank_3);
      // formData.append("bank_branch_3", this.bank_branch_1);
      // formData.append("id_currency_3", this.id_currency_3);
      // formData.append("payment_acc_number_3", this.payment_acc_number_3);
      // formData.append("payment_acc_name_3", this.payment_acc_name_3);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      };

      instance
        .post("individual-account/add-bank-account", formData, axiosConfig)
        .then((res) => {
          console.log("AXIOS SUCCESS: ", res);
          this.loading = !false;

          window.setTimeout(function () {
            window.location.href = "/profil-risiko";
          }, 2000);
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          this.failedShow = true;

          // this.errorMessage = err.response.data.data.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.register-header-container {
  padding-top: 120px;
  padding-bottom: 40px;
}

.register-header-row {
  text-align: left !important;
}

.register-form {
  background: #f3f4f6;
}
.register-form-container {
  padding-top: 40px;
  padding-bottom: 60px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #4b5563;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e !important;
  }
}

.register-header h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #111827;
}
.register-header p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6b7280;
}

.mx-input {
  height: 38px !important;
}

.mx-input:hover,
.mx-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
}

.vs-con-input-label {
  width: 100%;
}

.con-select {
  width: 100%;
  clear: both;
}

.con-vs-radio {
  justify-content: start;
}

.con-vs-checkbox {
  justify-content: start;
  margin: 0;
}

.ekyc-form-input h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #111827;
}

.ekyc-form-input p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #6b7280;
}

.ekyc-form-input span {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #9ca3af;
}

.ekyc-form-radio span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #6b7280;
}

.ekyc-form-radio h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #374151;
}

.ekyc-form-radio p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #9ca3af;
}

.ekyc-form-fatcha p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #374151;
}

.residental-address-checkbox h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #374151;
}

.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.radio {
  position: relative;
  input[type="radio"] {
    opacity: 0;
  }
  label {
    padding-left: 10px;
    cursor: pointer;
    margin-bottom: 7px !important;
    &:before {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 1px;
      left: 0;
      content: "";
      display: inline-block;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      border: 1px solid $secondaryColor;
      background: white;
    }
  }
  input[type="radio"] {
    margin: 0px;
    &:disabled + label {
      color: #999;
      &:before {
        background-color: $secondaryColor;
      }
    }
    &:checked + label::after {
      content: "";
      position: absolute;
      top: 5px;
      left: 4px;
      display: inline-block;
      font-size: 11px;
      width: 10px;
      height: 10px;
      background-color: $secondaryColor;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
    }
  }
}

.radio-default input[type="radio"]:checked + label:before {
  border-color: $secondaryColor;
}

.ekyc-form-radio input[type="radio"]:checked + label {
  &:before {
    border-color: $secondaryColor;
  }
  &::after {
    background-color: $secondaryColor;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $secondaryColor;
}

.custom-checkbox:active {
  box-shadow: none !important;
}
.custom-checkbox:focus {
  box-shadow: none !important;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #111827;
}

.jumbotron {
  padding: 0;
  background-color: white;
  opacity: 1;
}

.modal-register {
  z-index: 99999;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: black;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #4b5563;
  }
}

.btn-add-bank {
  font-family: $inter;
  background: transparent;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #3f0975;
  border: 1px solid #3f0975;
  border-radius: 6px;
}

.btn-tertiary:disabled {
  cursor: no-drop;
}

.btn-dark:focus {
  box-shadow: none !important;
}

.dropbox {
  background: white;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px;
  top: 200; /* minimum height */
  position: relative;
  border-radius: 5px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  cursor: pointer;
}

.dropbox:hover {
  outline: 2px dashed grey; /* the dash box */ /* when mouse over to the drop zone, change color */
}

.dropbox p {
  position: relative;
  font-size: 1.2em;
  text-align: center;
  top: 50px;
}

.dropbox-signature {
  background: white;
  height: 200px;
  border-radius: 5px;
}
.dropbox-signature:hover {
  outline: 2px dashed grey; /* the dash box */
}

.selfie-instruction {
  margin-top: 64px;
}

@media only screen and (max-width: 768px) {
  .register-header-row {
    text-align: center !important;
  }
  .btn-dark {
    margin-bottom: 18px;
  }
  .btn-submit-row {
    justify-content: center !important;
  }
  .header-modal {
    font-size: 16px !important;
  }
  .selfie-instruction {
    margin-top: 0px;
  }
}
</style>